// DEPENDENCIES
import React, {
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useRouter } from "next/router";
import {
  Button as DefaultButton,
  Header,
  Heading,
  Link,
  Box,
  Text,
  TextField,
  Select,
  IconFa,
  crukTheme,
  ThemeType,
} from "@cruk/cruk-react-components";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

// COMPONENTS
import styled, { ThemeProvider, css } from "styled-components";
import header from "../configs/headers";
import ThemeNameContext from "../contexts/ThemeNameContext";
import GlobalStyle from "../globalStyle";
import FormHead from "../components/FormHead";
import Footer from "../components/Footer";
import { MaxWidth } from "../components/styles";
import { newCrukTheme } from "../components/newCrukTheme";
import GetSelectOptions from "../components/GetSelectOptions";
import PhoneNumber from "../components/PhoneNumber";
import Email from "../components/Email";
import researchAreas from "../data/researchAreas";
import {
  setCookieAccepted,
  setCookieBanner,
  setDatadog,
} from "../helpers/functions/shared";
import { amountRegExp } from "../helpers/regexp";
import useDataLayer from "../helpers/useDataLayer";
import {
  OptimizelyContext,
  OptimizelyContextProvider,
} from "../contexts/OptimizelyContext";
import OptimizelySetup from "../components/OptimizelySetup";
import useOptimizelyTrack from "../helpers/useOptimizelyTrack";

const Button = styled(DefaultButton)`
  font-family: ${({ theme }) => theme.typography.fontFamilyBase};
  font-weight: normal;
`;

const SiteContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

const FullBleedContainer = styled.div<{
  backgroundColor?: string;
  desktopOnly?: boolean;
  marginBlock?: "small" | "large";
}>`
  width: 100%;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor ?? theme.tokenColors.grey_200};
  ${({ marginBlock }) =>
    marginBlock
      ? css`
          margin-block-start: ${marginBlock === "small" ? "1.5rem" : "5rem"};
          margin-block-end: ${marginBlock === "small" ? "0.5rem" : "5rem"};

          @media (min-width: ${({ theme }) => theme.breakpoint.mobile}) {
            margin-block: unset;
          }
        `
      : ""}

  ${({ desktopOnly }) =>
    desktopOnly
      ? css`
          display: none;

          @media (min-width: ${({ theme }) => theme.breakpoint.mobile}) {
            display: block;
          }
        `
      : ""}
`;

const ContentWidth = styled.div`
  margin-inline: auto;

  @media (min-width: ${({ theme }) => theme.breakpoint.mobile}) {
    max-width: ${({ theme }) => theme.utilities.contentMaxWidth};
    padding-inline: min(1vw, 6rem);
  }
`;

const PageContent = styled.div`
  padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.s};

  @media (min-width: ${({ theme }) => theme.breakpoint.mobile}) {
    padding: ${({ theme }) => theme.spacing.m} ${({ theme }) => theme.spacing.s};
  }
`;

type FullBleedContentProps = {
  backgroundColor?: string;
  hero?: boolean;
  children?: ReactNode;
  desktopOnly?: boolean;
  marginBlock?: "small" | "large";
};

const FullBleedContent = ({
  backgroundColor,
  hero = false,
  children,
  marginBlock,
  desktopOnly = false,
}: FullBleedContentProps): ReactElement => (
  <FullBleedContainer
    backgroundColor={hero ? "white" : backgroundColor}
    desktopOnly={desktopOnly}
    marginBlock={marginBlock}
  >
    {hero ? (
      <HeroContainer>{children}</HeroContainer>
    ) : (
      <PageContent>
        <ContentWidth>{children}</ContentWidth>
      </PageContent>
    )}
  </FullBleedContainer>
);

const AlignedForm = styled.form`
  background-color: ${({ theme }) => theme.tokenColors.white};
  padding-block: unset;
  padding-inline: 0.5rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.mobile}) {
    padding: unset;
    position: absolute;

    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
    inset: 0 0 auto auto;

    margin-right: min(15vw, 15%);
    margin-top: 2rem;
    width: 22rem;
  }
`;

const nudges = {
  monthly: [
    {
      amount: 6,
      impact:
        "£6 a month for five years could fund an international trial for one day, aiming to improve treatment for brain tumours in children.",
    },
    {
      amount: 15,
      impact:
        "£15 a month for five years could fund one of our amazing cancer nurses for one week.",
    },
    {
      amount: 30,
      impact:
        "£30 a month for five years could fund a Cancer Research PhD student for a whole month, so they can make the research breakthroughs of the future.",
    },
  ],
  single: [
    {
      amount: 10,
      impact:
        "£10 could buy 200 plastic test tubes, the understated tools of cancer-fighting experiments.",
    },
    {
      amount: 20,
      impact:
        "£20 could buy a digital PCR assay, which scans a piece of genetic material to find cancer-causing mutations.",
    },
    {
      amount: 30,
      impact:
        "£30 could fund one of our expert nurses for two hours on our cancer information hotline, offering invaluable support and guidance to people affected by cancer.",
    },
  ],
};

const FrequencyTabs = styled.fieldset`
  margin: unset;

  div {
    background: transparent;
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
  }

  div label input {
    position: absolute;
    cursor: pointer;
    left: 0;
    top: 0;
    width: 100%;
    margin: 0;
    zoom: 1;
    opacity: 0;
    z-index: 1;
  }
`;

const FrequencyTab = styled.label<{ checked: boolean }>`
  cursor: pointer;
  position: relative;
  font-family: ${({ theme }) => theme.typography.fontFamilyHeadings};
  font-size: 1.125rem;
  color: ${({ theme }) => theme.tokenColors.darkBlue_500};
  font-weight: normal;
  text-align: center;
  padding: 0.5rem 2rem;
  margin: 0;
  border-style: solid;
  border-width: ${({ theme }) => theme.button.buttonBorderThickness};

  transition: background-color 250ms ease, border-color 250ms ease,
    color 250ms ease;

  &:focus-within,
  &:hover {
    background-color: ${({ theme }) => theme.tokenColors.darkBlue_500};
    border-color: ${({ theme }) => theme.tokenColors.darkBlue_500};
    color: ${({ theme }) => theme.colors.textLight};
  }

  ${({ theme, checked }) =>
    checked
      ? css`
          background-color: ${theme.tokenColors.darkBlue_500};
          border-color: ${theme.tokenColors.darkBlue_500};
          color: ${theme.colors.textLight};
        `
      : css`
          border-color: ${theme.tokenColors.darkBlue_100};
          background-color: ${theme.tokenColors.darkBlue_100};
        `}

  @media (width < 380px) {
    font-size: 1rem;
  }
`;

const NudgeAmounts = styled.fieldset`
  margin: unset;
  padding-inline: 1.125rem;
  padding-block-start: 1rem;
  padding-block-end: 0.5rem;

  div:first-child {
    background: transparent;
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.125rem;
  }

  div label input {
    position: absolute;
    cursor: pointer;
    left: 0;
    top: 0;
    width: 100%;
    margin: 0;
    zoom: 1;
    opacity: 0;
    z-index: 1;
  }
`;

// TODO Consider using `.has` css pseudo selector for applying checked style
const NudgeAmount = styled.label<{ checked: boolean }>`
  cursor: pointer;
  position: relative;
  font-family: ${({ theme }) => theme.typography.fontFamilyBase};
  font-size: 1.125rem;
  color: ${({ theme }) => theme.tokenColors.black};
  font-weight: normal;
  text-align: center;
  padding-block: 0.5rem;
  margin: 0;
  border-style: solid;
  border-width: 1px;

  transition: background-color 250ms ease, border-color 250ms ease,
    color 250ms ease;

  &:focus-within,
  &:hover {
    background-color: ${({ theme }) => theme.tokenColors.darkBlue_500};
    border-color: ${({ theme }) => theme.tokenColors.darkBlue_500};
    color: ${({ theme }) => theme.colors.textLight};
  }

  ${({ theme, checked }) =>
    checked
      ? css`
          background-color: ${theme.tokenColors.darkBlue_500};
          border-color: ${theme.tokenColors.darkBlue_500};
          color: ${theme.colors.textLight};
        `
      : css`
          border-color: ${theme.tokenColors.black};
        `}
`;

const Logos = styled.div`
  display: flex;
  gap: 0.5rem;
  padding-block: 0.5rem;
  padding-inline: 0.5rem;

  img {
    max-height: 2.5rem;
    min-width: 0;
    width: fit-content;
  }

  img:nth-child(5) {
    max-height: 2rem;
    align-self: center;
  }
`;

type ShortFormProps = {
  config: typeof nudges;
};

const OtherAmountLabel = styled.label`
  font-weight: ${({ theme }) => theme.typography.fontWeightHeavy};
  font-size: ${({ theme }) => theme.fontSizes.s};
  text-align: center;
  margin: unset;
`;

const ShortForm = ({ config }: ShortFormProps): ReactElement => {
  const [frequency, setFrequency] = useState<"single" | "monthly">("monthly");
  const [nudgeIndex, setNudgeIndex] = useState<number | null>(null);
  const [otherAmount, setOtherAmount] = useState<string>("");

  const router = useRouter();
  const { pushDonatePageInteraction } = useDataLayer();

  useEffect(() => {
    setNudgeIndex(null);
    setOtherAmount("");
  }, [frequency]);

  return (
    <AlignedForm
      onSubmit={(e) => {
        e.preventDefault();

        pushDonatePageInteraction("button click", "main donate cta");

        const formEl = e.target as HTMLFormElement;
        const rawFormData = new FormData(formEl);
        const formData = Object.fromEntries(
          Array.from(rawFormData.entries()).filter(([, value]) => !!value)
        );

        // TODO replace with proper link
        const baseUrl = "/support-us/your-donation";
        const frequencyQuery =
          formData.frequency && formData.frequency === "monthly"
            ? "?type=regular&frequency=Monthly"
            : "?type=single";
        const amountQuery = formData.amount
          ? `&amount=${formData.amount.toString()}`
          : "";
        const url = `${baseUrl}${frequencyQuery}${amountQuery}`;
        router.push(url).catch(console.error);
      }}
    >
      <FrequencyTabs>
        <div>
          <FrequencyTab htmlFor="monthly" checked={frequency === "monthly"}>
            <input
              type="radio"
              name="frequency"
              id="monthly"
              value="monthly"
              checked={frequency === "monthly"}
              onChange={() => setFrequency("monthly")}
            />
            Monthly
          </FrequencyTab>

          <FrequencyTab htmlFor="single" checked={frequency === "single"}>
            <input
              type="radio"
              name="frequency"
              id="single"
              value="single"
              checked={frequency === "single"}
              onChange={() => setFrequency("single")}
            />
            Single
          </FrequencyTab>
        </div>
      </FrequencyTabs>
      <NudgeAmounts>
        <div>
          {config[frequency].map(({ amount }, id) => (
            <NudgeAmount
              htmlFor={`amount-${amount}`}
              id={`amount-label-${amount}`}
              key={`amount-label-${amount}`}
              checked={nudgeIndex === id}
            >
              <input
                type="radio"
                name="amount"
                id={`amount-${amount}`}
                value={`${amount}`}
                checked={nudgeIndex === id}
                onChange={() => {
                  setNudgeIndex(id);
                  setOtherAmount("");
                }}
              />
              £<span>{amount}</span>
            </NudgeAmount>
          ))}
        </div>
        <Box paddingVertical="xs" margin="none">
          {config[frequency].map(
            ({ amount, impact }, id) =>
              impact && (
                <Text
                  textSize="s"
                  textAlign="center"
                  margin="none"
                  hidden={nudgeIndex !== id}
                  id={`${frequency}-impact-${amount}`}
                  key={`${frequency}-impact-${amount}`}
                >
                  {impact}
                </Text>
              )
          )}
        </Box>
        <div>
          <OtherAmountLabel htmlFor="amount-other">
            Or enter another amount
          </OtherAmountLabel>

          <Box paddingVertical="xxs">
            <TextField
              type="text"
              name="amount"
              id="amount-other"
              value={otherAmount}
              inputMode="decimal"
              pattern={amountRegExp.source}
              onInvalid={(e: any) =>
                e.target.setCustomValidity("Please enter a number.")
              }
              onInput={(e: any) => e.target.setCustomValidity("")}
              onChange={(e) => {
                setOtherAmount(e.target.value.trim());
                setNudgeIndex(null);
              }}
              extraLeft="£"
              label=""
            />
          </Box>
        </div>
      </NudgeAmounts>

      <Box paddingVertical="xxs" marginBottom="xxs">
        <CtaButton appearance="primary" type="submit">
          Donate now
        </CtaButton>
      </Box>

      <FullBleedContainer>
        <Logos>
          <img
            src="/assets/donate-landing-page/logos/logo-PayPal.svg"
            alt="PayPal logo"
          />
          <img
            src="/assets/donate-landing-page/logos/logo-ApplePay.svg"
            alt="Apple Pay logo"
          />
          <img
            src="/assets/donate-landing-page/logos/logo-Mastercard.svg"
            alt="Mastercard logo"
          />
          <img
            src="/assets/donate-landing-page/logos/logo-Maestro.svg"
            alt="Maestro logo"
          />
          <img
            src="/assets/donate-landing-page/logos/logo-Visa.svg"
            alt="Visa logo"
          />
        </Logos>
      </FullBleedContainer>
    </AlignedForm>
  );
};

// TODO Clean this up when the hero image is finalized
const testHeroImages = [
  { src: "/assets/donate-landing-page/hero_0.png", position: "center" },
  { src: "/assets/donate-landing-page/hero_1.jpg", position: "top center" },
  { src: "/assets/donate-landing-page/hero_2.png", position: "top right" },
  {
    src: "/assets/donate-landing-page/hero_3_medium.jpg",
    position: "top right",
  },
  { src: "/assets/donate-landing-page/hero_4.png", position: "center" },
];

type HeroProps = {
  imageIndex?: number;
};

const HeroContainer = styled.div`
  --hero-image-height: 500px;

  background-color: ${({ theme }) => theme.tokenColors.white};
  margin-inline: auto;

  @media (min-width: ${({ theme }) => theme.breakpoint.mobile}) {
    background-color: ${({ theme }) => theme.tokenColors.grey_200};
    position: relative;

    max-width: 1440px;
    max-height: var(--hero-image-height);
  }
`;

const HeroImage = styled.img<{ position?: string }>`
  object-fit: cover;
  object-position: ${({ position }) => position ?? "center"};
  height: 10rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.mobile}) {
    height: var(--hero-image-height);
  }
`;

const HeroStrapline = styled(Heading)`
  font-size: ${({ theme }) => theme.fontSizes.l};

  @media (min-width: ${({ theme }) => theme.breakpoint.mobile}) {
    font-size: ${({ theme }) => theme.fontSizes.xl};
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    font-size: ${({ theme }) => theme.fontSizes.xxl};
  }
`;

const Hero = ({ imageIndex = 0 }: HeroProps): ReactElement => (
  <div>
    <FullBleedContent hero>
      <HeroImage
        src={testHeroImages[imageIndex].src}
        position={testHeroImages[imageIndex].position}
        alt="Oliver enjoying an open-water swimming event"
      />
      <ShortForm config={nudges} />
    </FullBleedContent>
    <FullBleedContent
      backgroundColor={newCrukTheme.tokenColors.white}
      marginBlock="small"
    >
      <HeroStrapline margin="none" textColor={newCrukTheme.tokenColors.black}>
        Help us bring about a world where everybody lives longer, better lives,
        free from the fear of cancer.
      </HeroStrapline>
    </FullBleedContent>
  </div>
);

// TODO Custom small screen media query to account for Galaxy Fold like screen
const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: space-between;
  margin-block: unset;
  padding: var(--block-padding) 0;

  transition: max-height 0.2s ease-in-out 50ms, padding 0.3s ease-in-out;
  max-height: var(--max-height);
  overflow: hidden;

  @media (width < 380px) {
    & a {
      font-size: 0.777rem;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    max-height: max-content;
    flex-direction: row;
    padding-block: 1rem;
    transition: unset;
  }
`;

const NavContainer = styled(MaxWidth)`
  position: relative;
  padding: ${({ theme }) => theme.spacing.xxs};

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    padding: 0 ${({ theme }) => theme.spacing.s};
  }
`;

const NavItem = styled.li<{ withBorder?: boolean; isSelected?: boolean }>`
  list-style: none;
  --sub-menu-display: none;
  --vertical-hover-range: 1rem;
  --horizontal-hover-range: 0.5rem;
  --horizontal-link-padding: 0.5rem;
  --vertical-link-padding: 0.5rem;

  & > a {
    font-family: ${({ theme }) => theme.typography.fontFamilyHeadings};
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  }

  & > a > span {
    display: none;
  }

  ${({ withBorder }) =>
    withBorder
      ? css`
          @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
            & > a {
              display: block;
              padding-block: var(--vertical-link-padding);
              padding-inline: var(--horizontal-link-padding);

              ${({ isSelected }) =>
                isSelected
                  ? css`
                      border: solid 1px #c6c6c6;
                      border-radius: 0.25rem;
                      padding-block: calc(var(--vertical-link-padding) - 1px);
                      padding-inline: calc(
                        var(--horizontal-link-padding) - 1px
                      );
                      color: ${({ theme }) => theme.colors.linkColorHover};
                    `
                  : ""}
            }
          }
        `
      : ""}

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    padding-block: var(--vertical-hover-range);
    margin-block: calc(var(--vertical-hover-range) * -1);

    padding-inline: var(--horizontal-hover-range);
    margin-inline: calc(var(--horizontal-hover-range) * -1);

    &:hover {
      --sub-menu-display: block;
    }

    & > a > span {
      display: inline;
      padding-inline-start: 0.25rem;
    }
  }
`;

type MenuItemContent = {
  label: string;
  url: string;
};

type SubMenuContent = {
  label: string;
  content: Array<MenuItemContent>;
};

type MenuItem = {
  title: MenuItemContent;
  subMenu: Array<SubMenuContent>;
};

// About cancer submenu
const cancerTypesMenu = {
  label: "cancer-type-menu",
  content: [
    {
      label: "Cancer types",
      url: "https://www.cancerresearchuk.org/about-cancer/type",
    },
    {
      label: "Breast cancer",
      url: "https://www.cancerresearchuk.org/about-cancer/breast-cancer",
    },
    {
      label: "Bowel cancer",
      url: "https://www.cancerresearchuk.org/about-cancer/bowel-cancer",
    },
    {
      label: "Lung cancer",
      url: "https://www.cancerresearchuk.org/about-cancer/lung-cancer",
    },
    {
      label: "Prostate cancer",
      url: "https://www.cancerresearchuk.org/about-cancer/prostate-cancer",
    },
    {
      label: "More...",
      url: "https://www.cancerresearchuk.org/about-cancer/type",
    },
  ],
};

const cancersInGeneralMenu = {
  label: "cancers-in-general-menu",
  content: [
    {
      label: "Cancer in general",
      url: "https://www.cancerresearchuk.org/about-cancer/cancer-in-general",
    },
    {
      label: "Symptoms",
      url: "https://www.cancerresearchuk.org/about-cancer/cancer-symptoms",
    },
    {
      label: "Screening",
      url: "https://www.cancerresearchuk.org/about-cancer/screening",
    },
    {
      label: "Tests",
      url: "https://www.cancerresearchuk.org/about-cancer/tests-and-scans",
    },
    {
      label: "Treatment",
      url: "https://www.cancerresearchuk.org/about-cancer/treatment",
    },
    {
      label: "Clinical trials",
      url: "https://www.cancerresearchuk.org/about-cancer/find-a-clinical-trial",
    },
  ],
};

const causesOfCancerMenu = {
  label: "causes-of-cancer-menu",
  content: [
    {
      label: "Causes of cancer",
      url: "https://www.cancerresearchuk.org/about-cancer/causes-of-cancer",
    },
    {
      label: "Smoking",
      url: "https://www.cancerresearchuk.org/about-cancer/causes-of-cancer/smoking-and-cancer",
    },
    {
      label: "Alcohol",
      url: "https://www.cancerresearchuk.org/about-cancer/causes-of-cancer/alcohol-and-cancer",
    },
    {
      label: "Diet",
      url: "https://www.cancerresearchuk.org/about-cancer/causes-of-cancer/diet-and-cancer",
    },
    {
      label: "Obesity",
      url: "https://www.cancerresearchuk.org/about-cancer/causes-of-cancer/bodyweight-and-cancer",
    },
    {
      label: "Sun and UV",
      url: "https://www.cancerresearchuk.org/about-cancer/causes-of-cancer/sun-uv-and-cancer",
    },
  ],
};

const copingWithCancerMenu = {
  label: "coping-with-cancer-menu",
  content: [
    {
      label: "Coping with cancer",
      url: "https://www.cancerresearchuk.org/about-cancer/coping-with-cancer",
    },
    {
      label: "Managing symptoms and side effects",
      url: "https://www.cancerresearchuk.org/about-cancer/coping-with-cancer/coping-physically",
    },
    {
      label: "Mental health and cancer",
      url: "https://www.cancerresearchuk.org/about-cancer/coping/mental-health-cancer",
    },
    {
      label: "Money and travel",
      url: "https://www.cancerresearchuk.org/about-cancer/coping-with-cancer/coping-practically",
    },
    {
      label: "Death and dying",
      url: "https://www.cancerresearchuk.org/about-cancer/coping-with-cancer/dying",
    },
    {
      label: "Cancer Chat forum",
      url: "https://cancerchat.cancerresearchuk.org",
    },
  ],
};

const healthProfessionalsMenu = {
  label: "health-professionals-menu",
  content: [
    {
      label: "Health Professionals",
      url: "https://www.cancerresearchuk.org/health-professional",
    },
    {
      label: "Cancer Statistics",
      url: "https://www.cancerresearchuk.org/health-professional/cancer-statistics",
    },
    {
      label: "Cancer Screening",
      url: "https://www.cancerresearchuk.org/health-professional/screening",
    },
    {
      label: "Learning and Support",
      url: "https://www.cancerresearchuk.org/health-professional/learning-and-support",
    },
    {
      label: "NICE suspected cancer referral guidelines",
      url: "https://www.cancerresearchuk.org/health-professional/learning-and-development-tools/nice-cancer-referral-guidelines",
    },
    {
      label: "More...",
      url: "https://www.cancerresearchuk.org/health-professional",
    },
  ],
};

const aboutCancerSubMenu = [
  cancerTypesMenu,
  cancersInGeneralMenu,
  causesOfCancerMenu,
  copingWithCancerMenu,
  healthProfessionalsMenu,
];

const aboutCancer: MenuItem = {
  title: {
    label: "About cancer",
    url: "https://www.cancerresearchuk.org/about-cancer/",
  },
  subMenu: aboutCancerSubMenu,
};

const donateMenu = {
  label: "donate-menu",
  content: [
    {
      label: "Donate",
      url: "https://www.cancerresearchuk.org/get-involved/donate",
    },
    {
      label: "Make a donation",
      url: "https://www.cancerresearchuk.org/get-involved/donate",
    },
    {
      label: "By cancer type",
      url: "https://www.cancerresearchuk.org/get-involved/donate/donate-by-cancer-type",
    },
    {
      label: "Leave a legacy gift",
      url: "https://www.cancerresearchuk.org/get-involved/leave-a-legacy-gift-in-your-will",
    },
    {
      label: "Donate in Memory",
      url: "https://www.cancerresearchuk.org/get-involved/donate/donate-in-memory",
    },
    {
      label: "More...",
      url: "https://www.cancerresearchuk.org/get-involved/donate",
    },
  ],
};

const findAnEventMenu = {
  label: "find-an-event-menu",
  content: [
    {
      label: "Find an event",
      url: "https://www.cancerresearchuk.org/get-involved/find-an-event",
    },
    {
      label: "Race for Life",
      url: "https://www.cancerresearchuk.org/get-involved/find-an-event/charity-runs/race-for-life",
    },
    {
      label: "Charity runs",
      url: "https://www.cancerresearchuk.org/get-involved/find-an-event/charity-runs",
    },
    {
      label: "Charity walks",
      url: "https://www.cancerresearchuk.org/get-involved/find-an-event/charity-walks-treks-and-hikes",
    },
    {
      label: "Search events",
      url: "https://www.cancerresearchuk.org/get-involved/find-an-event/search-results",
    },
    {
      label: "Relay For Life",
      url: "http://relay.cancerresearchuk.org",
    },
    {
      label: "More...",
      url: "https://www.cancerresearchuk.org/get-involved/find-an-event",
    },
  ],
};

const volunteerMenu = {
  label: "volunteer-menu",
  content: [
    {
      label: "Volunteer",
      url: "https://www.cancerresearchuk.org/get-involved/volunteer",
    },
    {
      label: "Volunteer in our shops",
      url: "https://www.cancerresearchuk.org/get-involved/volunteer/volunteer-in-our-shops",
    },
    {
      label: "Help at an event",
      url: "https://www.cancerresearchuk.org/get-involved/volunteer/help-at-an-event",
    },
    {
      label: "Help us raise money",
      url: "https://www.cancerresearchuk.org/get-involved/volunteer/help-us-raise-money",
    },
    {
      label: "Campaign for us",
      url: "https://www.cancerresearchuk.org/get-involved/campaign-for-us",
    },
    {
      label: "More...",
      url: "https://www.cancerresearchuk.org/get-involved/volunteer",
    },
  ],
};

const doYourOwnFundraisingMenu = {
  label: "do-your-own-fundraising-menu",
  content: [
    {
      label: "Do your own fundraising",
      url: "https://www.cancerresearchuk.org/get-involved/do-your-own-fundraising",
    },
    {
      label: "Fundraising ideas",
      url: "https://www.cancerresearchuk.org/get-involved/do-your-own-fundraising/fundraising-ideas",
    },
    {
      label: "Get a fundraising pack",
      url: "https://www.cancerresearchuk.org/get-involved/do-your-own-fundraising/get-fundraising-pack",
    },
    {
      label: "Return fundraising money",
      url: "https://www.cancerresearchuk.org/get-involved/do-your-own-fundraising/return-your-money",
    },
    {
      label: "Fundraise by cancer type",
      url: "https://www.cancerresearchuk.org/get-involved/do-your-own-fundraising/target-your-fundraising",
    },
    {
      label: "Set up a Cancer Research UK Giving Page",
      url: "https://www.cancerresearchuk.org/get-involved/do-your-own-fundraising/practical-fundraising-help/set-up-a-cancer-research-uk-giving-page",
    },
    {
      label: "More...",
      url: "https://www.cancerresearchuk.org/get-involved/do-your-own-fundraising",
    },
  ],
};

// TODO Apparently the "More..." heading doesn't have a url (only on desktop). Using the top level url for now
const getInvolvedMoreMenu = {
  label: "get-involved-more-menu",
  content: [
    {
      label: "More...",
      url: "https://www.cancerresearchuk.org/get-involved",
    },
    {
      label: "Find a shop or superstore",
      url: "https://www.cancerresearchuk.org/get-involved/find-a-shop",
    },
    {
      label: "Become a partner",
      url: "https://www.cancerresearchuk.org/get-involved/become-a-partner",
    },
    {
      label: "Cancer Research UK for Children & Young People",
      url: "https://www.cancerresearchuk.org/get-involved/donate/kids-and-teens",
    },
    {
      label: "Our We Are campaign",
      url: "https://www.cancerresearchuk.org/get-involved/we-are",
    },
  ],
};

const getInvolvedSubMenu = [
  donateMenu,
  findAnEventMenu,
  volunteerMenu,
  doYourOwnFundraisingMenu,
  getInvolvedMoreMenu,
];

const getInvolved: MenuItem = {
  title: {
    label: "Get involved",
    url: "https://www.cancerresearchuk.org/get-involved/",
  },
  subMenu: getInvolvedSubMenu,
};

const byCancerTypeMenu = {
  label: "by-cancer-type-menu",
  content: [
    {
      label: "By cancer type",
      url: "https://www.cancerresearchuk.org/our-research/our-research-by-cancer-type",
    },
    {
      label: "Brain tumours",
      url: "https://www.cancerresearchuk.org/our-research/our-research-by-cancer-type/our-research-on-brain-tumours",
    },
    {
      label: "Skin cancer",
      url: "https://www.cancerresearchuk.org/our-research/our-research-by-cancer-type/our-research-on-skin-cancer",
    },
    {
      label: "Breast cancer",
      url: "https://www.cancerresearchuk.org/our-research/our-research-by-cancer-type/our-research-into-breast-cancer",
    },
    {
      label: "All cancer types",
      url: "https://www.cancerresearchuk.org/our-research/our-research-by-cancer-type",
    },
  ],
};

const byCancerTopicMenu = {
  label: "by-cancer-topic-menu",
  content: [
    {
      label: "By cancer topic",
      url: "https://www.cancerresearchuk.org/our-research/our-research-by-cancer-subject",
    },
    {
      label: "New treatments",
      url: "https://www.cancerresearchuk.org/our-research/our-research-by-cancer-subject/our-research-on-clinical-trials",
    },
    {
      label: "Cancer biology",
      url: "https://www.cancerresearchuk.org/our-research/our-research-by-cancer-subject/our-research-on-cancer-biology",
    },
    {
      label: "Cancer drugs",
      url: "https://www.cancerresearchuk.org/our-research/our-research-by-cancer-subject/our-research-on-cancer-drugs",
    },
    {
      label: "All cancer subjects",
      url: "https://www.cancerresearchuk.org/our-research/our-research-by-cancer-subject",
    },
  ],
};

const nearYouMenu = {
  label: "near-you-menu",
  content: [
    {
      label: "Near you",
      url: "https://www.cancerresearchuk.org/our-research/our-research-near-you",
    },
    {
      label: "Belfast",
      url: "https://www.cancerresearchuk.org/our-research/our-research-near-you/our-research-in-belfast",
    },
    {
      label: "Cardiff",
      url: "https://www.cancerresearchuk.org/our-research/our-research-near-you/our-research-in-cardiff",
    },
    {
      label: "Edinburgh",
      url: "https://www.cancerresearchuk.org/our-research/our-research-near-you/our-research-in-edinburgh",
    },
    {
      label: "All locations",
      url: "https://www.cancerresearchuk.org/our-research/our-research-near-you",
    },
  ],
};

const byResearcherMenu = {
  label: "by-researcher-menu",
  content: [
    {
      label: "By Researcher",
      url: "https://www.cancerresearchuk.org/our-research/researchers",
    },
    {
      label: "Professor Duncan Baird",
      url: "https://www.cancerresearchuk.org/researchers/professor-duncan-baird",
    },
    {
      label: "Professor Fran Balkwill",
      url: "https://www.cancerresearchuk.org/researchers/professor-fran-balkwill",
    },
    {
      label: "Professor Andrew Biankin",
      url: "https://www.cancerresearchuk.org/researchers/professor-andrew-biankin",
    },
    {
      label: "See all researchers",
      url: "https://www.cancerresearchuk.org/researchers",
    },
  ],
};

const ourResearchMoreMenu = {
  label: "our-research-more-menu",
  content: [
    {
      label: "More...",
      url: "https://www.cancerresearchuk.org/our-research/",
    },
    {
      label: "Our achievements timeline",
      url: "https://www.cancerresearchuk.org/our-progress",
    },
    {
      label: "Our research strategy",
      url: "https://www.cancerresearchuk.org/funding-for-researchers/our-research-strategy",
    },
    {
      label: "Involving animals in research",
      url: "https://www.cancerresearchuk.org/our-research/involving-animals-in-research",
    },
  ],
};

const ourResearchSubMenu = [
  byCancerTypeMenu,
  byCancerTopicMenu,
  nearYouMenu,
  byResearcherMenu,
  ourResearchMoreMenu,
];

const ourResearch: MenuItem = {
  title: {
    label: "Our research",
    url: "https://www.cancerresearchuk.org/our-research/",
  },
  subMenu: ourResearchSubMenu,
};

const researchOpportunitiesMenu = {
  label: "research-opportunities-menu",
  content: [
    {
      label: "Research opportunities",
      url: "https://www.cancerresearchuk.org/funding-for-researchers",
    },
    {
      label: "For discovery researchers",
      url: "https://www.cancerresearchuk.org/funding-for-researchers/research-opportunities-in-basic-science-and-discovery-research",
    },
    {
      label: "For clinical researchers",
      url: "https://www.cancerresearchuk.org/funding-for-researchers/clinical-research",
    },
    {
      label: "For population researchers",
      url: "https://www.cancerresearchuk.org/funding-for-researchers/research-opportunities-in-population-and-behavioural-research",
    },
    {
      label: "In drug discovery & development",
      url: "https://www.cancerresearchuk.org/funding-for-researchers/drug-discovery-and-development",
    },
    {
      label: "In early detection & diagnosis",
      url: "https://www.cancerresearchuk.org/funding-for-researchers/research-opportunities-in-early-detection-and-diagnosis",
    },
    {
      label: "For students & postdocs",
      url: "https://www.cancerresearchuk.org/funding-for-researchers/opportunities-for-phd-students-and-postdoc-researchers",
    },
  ],
};

const ourFundingSchemesMenu = {
  label: "our-funding-schemes-menu",
  content: [
    {
      label: "Our funding schemes",
      url: "https://www.cancerresearchuk.org/funding-for-researchers/our-funding-schemes?items_per_page=40",
    },
    {
      label: "Career Development Fellowship",
      url: "https://www.cancerresearchuk.org/funding-for-researchers/our-funding-schemes/career-development-fellowship",
    },
    {
      label: "Discovery Programme Awards",
      url: "https://www.cancerresearchuk.org/funding-for-researchers/our-funding-schemes/discovery-programme-awards",
    },
    {
      label: "Clinical Trial Award",
      url: "https://www.cancerresearchuk.org/funding-for-researchers/our-funding-schemes/clinical-trial-award-0",
    },
    {
      label: "Biology to Prevention Award",
      url: "https://www.cancerresearchuk.org/funding-for-researchers/our-funding-schemes/biology-to-prevention-award",
    },
    {
      label: "View all schemes and deadlines",
      url: "https://www.cancerresearchuk.org/funding-for-researchers/our-funding-schemes",
    },
  ],
};

const applyingForFundingMenu = {
  label: "applying-for-funding-menu",
  content: [
    {
      label: "Applying for funding",
      url: "https://www.cancerresearchuk.org/funding-for-researchers/applying-for-funding",
    },
    {
      label: "Start your application online",
      url: "https://www.cancerresearchuk.org/funding-for-researchers/applying-for-funding/start-your-funding-application-online",
    },
    {
      label: "How to make a successful application",
      url: "https://www.cancerresearchuk.org/funding-for-researchers/applying-for-funding/how-to-make-a-successful-application",
    },
    {
      label: "Funding committees",
      url: "https://www.cancerresearchuk.org/funding-for-researchers/applying-for-funding/funding-committees",
    },
    {
      label: "Successful applicant case studies",
      url: "https://www.cancerresearchuk.org/funding-for-researchers/applying-for-funding/how-to-make-a-successful-application/successful-applicant-case-studies",
    },
  ],
};

const howWeDeliverResearchMenu = {
  label: "how-we-deliver-research-menu",
  content: [
    {
      label: "How we deliver research",
      url: "https://www.cancerresearchuk.org/funding-for-researchers/how-we-deliver-research",
    },
    {
      label: "Our research strategy",
      url: "https://www.cancerresearchuk.org/funding-for-researchers/our-research-strategy",
    },
    {
      label: "Our research infrastructure",
      url: "https://www.cancerresearchuk.org/funding-for-researchers/our-research-infrastructure",
    },
    {
      label: "Events and conferences",
      url: "https://www.cancerresearchuk.org/funding-for-researchers/research-events-and-conferences",
    },
    {
      label: "Our research partnerships",
      url: "https://www.cancerresearchuk.org/funding-for-researchers/how-we-deliver-research/our-research-partnerships",
    },
    {
      label: "Facts & figures about our funding",
      url: "https://www.cancerresearchuk.org/funding-for-researchers/facts-and-figures-about-our-research-funding",
    },
  ],
};

const fundingForResearchersMoreMenu = {
  label: "funding-for-researchers-more-menu",
  content: [
    {
      label: "More...",
      url: "https://www.cancerresearchuk.org/funding-for-researchers/",
    },
    {
      label: "Develop your research career",
      url: "https://www.cancerresearchuk.org/funding-for-researchers/research-career-development-opportunities",
    },
    {
      label: "Recently funded awards",
      url: "https://www.cancerresearchuk.org/funding-for-researchers/how-we-deliver-research/recently-funded-awards",
    },
    {
      label: "Manage your research grant",
      url: "https://www.cancerresearchuk.org/funding-for-researchers/manage-your-research-grant",
    },
    {
      label: "Notify us of new publications",
      url: "https://www.cancerresearchuk.org/home/funding-for-researchers/manage-your-research-grant/tell-us-about-new-publications",
    },
  ],
};

const fundingForResearchersSubMenu = [
  researchOpportunitiesMenu,
  ourFundingSchemesMenu,
  applyingForFundingMenu,
  howWeDeliverResearchMenu,
  fundingForResearchersMoreMenu,
];

const funding: MenuItem = {
  title: {
    label: "Funding for researchers",
    url: "https://www.cancerresearchuk.org/funding-for-researchers/",
  },
  subMenu: fundingForResearchersSubMenu,
};

const findAShopMenu = {
  label: "find-a-shop-menu",
  content: [
    {
      label: "Find a shop",
      url: "https://www.cancerresearchuk.org/get-involved/find-a-shop",
    },
    {
      label: "Volunteer in a shop",
      url: "https://www.cancerresearchuk.org/get-involved/volunteer/volunteer-in-our-shops",
    },
    {
      label: "Donate goods to a shop",
      url: "https://www.cancerresearchuk.org/get-involved/donate/donate-goods",
    },
    {
      label: "Our superstores",
      url: "https://www.cancerresearchuk.org/get-involved/ways-to-shop/superstores",
    },
  ],
};

const shopOnlineMenu = {
  label: "shop-online-menu",
  content: [
    {
      label: "Shop online",
      url: "https://shop.cancerresearchuk.org/",
    },
    {
      label: "Race for Life",
      url: "https://shop.cancerresearchuk.org/fundraising-events/race-for-life",
    },
    {
      label: "Wedding favours",
      url: "https://shop.cancerresearchuk.org/products/wedding-favours",
    },
    {
      label: "Cancer Care",
      url: "https://shop.cancerresearchuk.org/cancer-care",
    },
    {
      label: "Flower Shop",
      url: "https://flowershop.cancerresearchuk.org/",
    },
  ],
};

const ourEbayShopMenu = {
  label: "our-ebay-shop-menu",
  content: [
    {
      label: "Our eBay store",
      url: "https://www.ebay.co.uk/str/ukcancerresearch",
    },
    {
      label: "Dresses",
      url: "https://www.ebay.co.uk/str/ukcancerresearch/Dresses/_i.html?_storecat=13763243016",
    },
    {
      label: "Shoes and boots",
      url: "https://www.ebay.co.uk/str/ukcancerresearch/Shoes-and-Boots/_i.html?_storecat=13763242016",
    },
    {
      label: "Bags and purses",
      url: "https://www.ebay.co.uk/str/ukcancerresearch/Bags-Purses/_i.html?_storecat=13869673016",
    },
  ],
};

const shopSubMenu = [findAShopMenu, shopOnlineMenu, ourEbayShopMenu];

const shop: MenuItem = {
  title: {
    label: "Shop",
    url: "https://www.cancerresearchuk.org/get-involved/ways-to-shop/",
  },
  subMenu: shopSubMenu,
};

const whatWeDoMenu = {
  label: "what-we-do-menu",
  content: [
    {
      label: "What we do",
      url: "https://www.cancerresearchuk.org/about-us",
    },
    {
      label: "We beat cancer",
      url: "https://www.cancerresearchuk.org/our-research",
    },
    {
      label: "We fundraise",
      url: "https://www.cancerresearchuk.org/get-involved/how-your-support-helps",
    },
    {
      label: "We develop policy",
      url: "https://www.cancerresearchuk.org/about-us/we-develop-policy",
    },
    {
      label: "Our global role",
      url: "https://www.cancerresearchuk.org/global-role",
    },
  ],
};

const ourOrganisationMenu = {
  label: "our-organisation-menu",
  content: [
    {
      label: "Our organisation",
      url: "https://www.cancerresearchuk.org/about-us/our-organisation",
    },
    {
      label: "Our strategy",
      url: "https://www.cancerresearchuk.org/about-us/our-organisation/beating-cancer-sooner-our-strategy",
    },
    {
      label: "Our Trustees",
      url: "https://www.cancerresearchuk.org/about-us/how-we-are-run/our-trustees",
    },
    {
      label: "CEO and Executive Board",
      url: "https://www.cancerresearchuk.org/about-us/how-we-are-run/chief-executive-and-executive-board",
    },
    {
      label: "How we spend your money",
      url: "https://www.cancerresearchuk.org/about-us/our-organisation/how-we-spend-your-money",
    },
  ],
};

const careersMenu = {
  label: "careers-menu",
  content: [
    {
      label: "Careers",
      url: "https://www.cancerresearchuk.org/about-us/careers",
    },
    {
      label: "Early careers",
      url: "https://www.cancerresearchuk.org/about-us/careers/early-in-career-opportunities",
    },
    {
      label: "Our teams",
      url: "https://www.cancerresearchuk.org/about-us/careers/teams",
    },
    {
      label: "Benefits",
      url: "https://www.cancerresearchuk.org/about-us/careers/benefits",
    },
  ],
};

const cancerNewsMenu = {
  label: "cancer-news-menu",
  content: [
    {
      label: "Cancer news",
      url: "https://news.cancerresearchuk.org/",
    },
    {
      label: "News",
      url: "https://news.cancerresearchuk.org/latest-news/",
    },
    {
      label: "Analysis",
      url: "https://news.cancerresearchuk.org/news-type/analysis/",
    },
    {
      label: "Opinion",
      url: "https://news.cancerresearchuk.org/news-type/opinion/",
    },
  ],
};

// TODO The "More..." disappeared for "Contact us" in mobile, so removed the "More..." for "Contact us" at top-level
const aboutUsMoreMenu = {
  label: "about-us-more-menu",
  content: [
    {
      label: "Contact us",
      url: "https://www.cancerresearchuk.org/about-us/contact-us",
    },
    {
      label: "Press office",
      url: "https://www.cancerresearchuk.org/about-us/press-office",
    },
    {
      label: "Publications",
      url: "https://publications.cancerresearchuk.org",
    },
    {
      label: "Update your contact preferences",
      url: "https://www.cancerresearchuk.org/about-us/contact-us/opt-in-form",
    },
  ],
};

const aboutUsSubMenu = [
  whatWeDoMenu,
  ourOrganisationMenu,
  careersMenu,
  cancerNewsMenu,
  aboutUsMoreMenu,
];

const aboutUs: MenuItem = {
  title: {
    label: "About us",
    url: "https://www.cancerresearchuk.org/about-us/",
  },
  subMenu: aboutUsSubMenu,
};

const menu = [aboutCancer, getInvolved, ourResearch, funding, shop, aboutUs];

const createIdFromLabel = (label: string) =>
  label.replace(/\s/g, "-").toLowerCase();

type SubMenuItemProps = {
  category: Array<MenuItemContent>;
  id: string;
};

const Categories = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 2rem;
  justify-content: start;

  position: absolute;
  z-index: 10;
  background-color: ${({ theme }) => theme.colors.backgroundLight};

  top: 3.5rem;
  right: 1.25rem;
  bottom: auto;
  left: 1.25rem;
  inset: 3.5rem 1.25rem auto 1.25rem;

  border: solid 1px #c6c6c6;

  padding-block: 1rem;
  padding-inline: 1.5rem;
`;

const Category = styled.ul`
  padding: unset;

  li {
    list-style: none;
    padding-block-end: 0.8rem;
  }

  li > a {
    font-family: ${({ theme }) => theme.typography.fontFamilyHeadings};
    text-decoration: none;
    color: ${({ theme }) => theme.colors.textDark};
    transition: color 250ms ease, text-decoration-color 250ms ease;
  }

  li:first-child > a {
    text-decoration-line: underline;
    text-decoration-color: transparent;
    color: ${({ theme }) => theme.tokenColors.darkBlue_500};
  }

  li:first-child > a:hover {
    text-decoration-color: ${({ theme }) => theme.tokenColors.darkBlue_500};
    color: ${({ theme }) => theme.tokenColors.darkBlue_500};
  }

  li > a:hover {
    color: ${({ theme }) => theme.tokenColors.magenta_500};
  }
`;

const SubMenuItem = ({ category, id }: SubMenuItemProps) => (
  <Category id={id}>
    {category.map((item) => (
      <li
        id={`${id}-${createIdFromLabel(item.label)}`}
        key={`${id}-${createIdFromLabel(item.label)}`}
      >
        <Link href={item.url}>{item.label}</Link>
      </li>
    ))}
  </Category>
);

// TODO Fix height calculation to be more robust. Currently, this is container height - top offset - all padding
// TODO Custom small screen media query to account for Galaxy Fold like screen
const MobileCategory = styled.ul`
  border: solid 1px #c6c6c6;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding-block-start: 0.5rem;
  padding-inline: 1rem;
  position: absolute;

  top: calc(3.6rem + 0.5px);
  right: 0.5rem;
  bottom: auto;
  left: 57%;
  inset: calc(3.6rem + 0.5px) 0.5rem auto 57%;

  background-color: ${({ theme }) => theme.colors.backgroundLight};
  height: calc(100% - 3.6rem - 1.5rem - 0.5px);

  li {
    list-style: none;
    padding-block-end: 0.8rem;
  }

  li > a {
    font-family: ${({ theme }) => theme.typography.fontFamilyHeadings};
    text-decoration: none;
    color: ${({ theme }) => theme.colors.textDark};
    transition: color 250ms ease, text-decoration-color 250ms ease;
  }

  @media (width < 380px) {
    padding-block-start: unset;
    padding-inline: 0.5rem;

    li {
      padding-block-end: 0.5rem;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    display: none;
  }
`;

type MobileSubMenuItemProp = {
  category: MenuItemContent;
};

const MobileSubMenuItem = ({ category }: MobileSubMenuItemProp) => (
  <li id={`mobile-${createIdFromLabel(category.label)}`}>
    <Link href={category.url}>{category.label}</Link>
  </li>
);

type SubMenuProps = {
  content: MenuItem["subMenu"];
};

const SubMenu = ({ content: categories }: SubMenuProps) => (
  <Categories>
    {categories.map(({ label, content }) => (
      <SubMenuItem key={label} id={label} category={content} />
    ))}
  </Categories>
);

const SubMenuContainer = styled.div<{ isVisible?: boolean }>`
  ${({ isVisible }) => (isVisible ? "--sub-menu-display: block;" : "")}
  display: var(--sub-menu-display);
`;

type NavLinkProps = {
  content: MenuItem;
  id: string;
  isSelected: boolean;
  selectedLinkHandler: (label: string) => void;
};

type MobileSubMenuProps = SubMenuProps;

const MobileSubMenu = ({ content: categories }: MobileSubMenuProps) => (
  <MobileCategory>
    {categories.map(({ label, content }) => (
      <MobileSubMenuItem key={label} category={content[0]} />
    ))}
  </MobileCategory>
);

const isBigScreen = () =>
  typeof window !== "undefined" &&
  window.matchMedia(`(min-width: ${crukTheme.breakpoint.tablet})`).matches;

const NavLink = ({
  content,
  id,
  isSelected,
  selectedLinkHandler,
}: NavLinkProps): ReactElement => (
  <NavItem id={id} withBorder isSelected={isSelected}>
    <Link
      href={content.title.url}
      appearance="secondary"
      onClick={(e) => {
        if (!isBigScreen() && !isSelected) {
          e.preventDefault();
          selectedLinkHandler(id);
        }
      }}
    >
      {content.title.label}
      <span>
        <IconFa faIcon={faChevronDown} size="0.8rem" />
      </span>
    </Link>
    {isSelected && (
      <SubMenuContainer isVisible={isSelected}>
        <MobileSubMenu content={content.subMenu} />
      </SubMenuContainer>
    )}
    <SubMenuContainer>
      <SubMenu content={content.subMenu} />
    </SubMenuContainer>
  </NavItem>
);

type NavLinksProps = {
  menuItems: Array<MenuItem>;
  selectedLink: string;
  selectedLinkHandler: (label: string) => void;
};

const NavLinks = ({
  menuItems,
  selectedLink,
  selectedLinkHandler,
}: NavLinksProps): ReactElement => (
  <>
    {menuItems.map((item) => (
      <NavLink
        content={item}
        id={createIdFromLabel(item.title.label)}
        key={createIdFromLabel(item.title.label)}
        isSelected={selectedLink === createIdFromLabel(item.title.label)}
        selectedLinkHandler={selectedLinkHandler}
      />
    ))}
  </>
);

const NavToggle = styled(Button)`
  min-height: max-content;
  padding-block: 0.5rem;
  padding-inline-start: 0.5rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    display: none;
  }

  span svg {
    transform: rotate(var(--rotation));
    transition: transform 0.2s ease-in-out;
  }
`;

// TODO Consider switching to JS approach and calculate element height: https://carlanderson.xyz/how-to-animate-on-height-auto/
const NavBar = (): ReactElement => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedLink, setSelectedLink] = useState("");
  return (
    <nav>
      <NavContainer>
        <NavToggle
          appearance="tertiary"
          aria-controls="primary-navigation"
          aria-expanded={menuOpen ? "true" : "false"}
          onClick={() => {
            setMenuOpen((prev) => !prev);
            setSelectedLink("");
          }}
          css={{
            "--rotation": menuOpen ? "0.5turn" : "0",
          }}
        >
          Menu
          <IconFa faIcon={faChevronDown} size="0.8rem" />
        </NavToggle>
        <NavList
          id="primary-navigation"
          style={
            {
              "--max-height": menuOpen ? "312px" : "0",
              "--block-padding": menuOpen ? "1rem" : "0",
            } as any
          }
        >
          <NavLinks
            menuItems={menu}
            selectedLink={selectedLink}
            selectedLinkHandler={setSelectedLink}
          />
        </NavList>
      </NavContainer>
    </nav>
  );
};

// TODO Consider using container queries instead of media queries
const horizontalGridTemplateArea = (rightAlignImage: boolean) => {
  const style = rightAlignImage
    ? `"heading image"
  "text image"
  `
    : `"image heading"
  "image text"
  `;

  return `grid-template-areas: ${style};`;
};

const Block = styled(Box)<{
  rightAlignImage: boolean;
  titleFirst: boolean;
  vertical: boolean;
  block: boolean;
  smallGap: boolean;
}>`
  --horizontal-gap: ${({ smallGap }) => (smallGap ? "2.5rem" : "4rem")};
  display: grid;
  gap: 1rem;

  grid-template-areas:
    "${({ titleFirst }) => (titleFirst ? "heading" : "image")}"
    "${({ titleFirst }) => (titleFirst ? "image" : "heading")}"
    "text";

  ${({ block }) =>
    block
      ? css`
          ${horizontalGridTemplateArea(false)}
        `
      : ""}

  @media (min-width: ${({ theme }) => theme.breakpoint.mobile}) {
    gap: 1rem var(--horizontal-gap);
    ${({ vertical, rightAlignImage }) =>
      vertical ? "" : horizontalGridTemplateArea(rightAlignImage)}
  }
`;

type ContentBlockProps = {
  imageSrc?: string | number;
  imageAlt?: string;
  hasIcon?: boolean;
  title?: string;
  asH3?: boolean;
  large?: boolean;
  children: ReactNode;
  rightAlignImage?: boolean;
  titleFirst?: boolean;
  vertical?: boolean;
  block?: boolean;
  imageSize?: "normal" | "small";
  smallGap?: boolean;
  bubble?: boolean;
};

const BlockImage = styled.div<{
  vertical: boolean;
  rightAlignImage: boolean;
  block: boolean;
}>`
  grid-area: image;
  margin-inline: auto;
  width: auto;
  text-align: center;

  ${({ block }) =>
    block
      ? css`
          align-self: center;
        `
      : ""}

  @media (min-width: ${({ theme }) => theme.breakpoint.mobile}) {
    text-align: ${({ vertical, rightAlignImage }) =>
      vertical ? "center" : rightAlignImage ? "start" : "end"};
  }
`;

const ContentAmountIcon = styled.div<{ isIcon: boolean }>`
  --size: 6rem;
  border-radius: 50%;
  width: var(--size);
  aspect-ratio: 1;
  margin-inline: auto;

  ${({ isIcon }) =>
    isIcon
      ? css`
          background-color: ${({ theme }) => theme.tokenColors.darkBlue_500};
        `
      : ""}

  * {
    line-height: var(--size);
  }
`;

const ContentAmount = styled.p`
  margin: unset;
  font-family: ${({ theme }) => theme.typography.fontFamilyHeadings};
  font-size: 2rem;
  color: ${({ theme }) => theme.tokenColors.white};
  font-weight: ${({ theme }) => theme.typography.fontWeightHeavy};
  text-align: center;
`;

const RoundImage = styled.img<{ size: "normal" | "small" }>`
  object-fit: cover;
  ${({ theme }) => (theme.name === "new_cruk" ? "" : "border-radius: 50%;")}
  width: ${({ size }) => (size === "normal" ? "min(80vw, 250px)" : "10rem")};
  max-width: ${({ size }) => (size === "normal" ? "250px" : "10rem")};
  aspect-ratio: 1;

  @media (min-width: ${({ theme }) => theme.breakpoint.mobile}) {
    width: ${({ size }) => (size === "normal" ? "min(35vw, 250px)" : "10rem")};
  }
`;

const RoundIcon = styled.div<{ size: "normal" | "small" }>`
  --size: ${({ size }) => (size === "normal" ? "min(100%, 175px)" : "8rem")};

  ${({ theme }) => (theme.name === "new_cruk" ? "" : "border-radius: 50%;")}
  width: var(--size);
  height: var(--size);
  ${({ theme }) =>
    theme.name === "new_cruk"
      ? ""
      : css`
          background-color: ${theme.tokenColors.darkBlue_100};
        `}
  margin-inline: auto;

  img {
    object-fit: cover;
    object-position: center;
    width: var(--size);
    aspect-ratio: 1;

    ${({ theme }) =>
      theme.name === "new_cruk" ? "" : "transform: scale(125%);"}
  }
`;

type ContentImageProps = {
  isIcon: boolean;
  size: "normal" | "small";
  src: string;
  alt: string;
};

const ContentImage = ({
  src,
  alt,
  isIcon,
  size,
}: ContentImageProps): ReactElement => {
  if (isIcon) {
    return (
      <RoundIcon size={size}>
        <img src={src} alt={alt} />
      </RoundIcon>
    );
  }

  return <RoundImage src={src} alt={alt} size={size} />;
};

const BlockHeading = styled(Heading)`
  grid-area: heading;
  align-self: end;
`;

const BlockH3StyledHeading = styled(Heading)`
  grid-area: heading;
  align-self: end;

  font-size: ${({ theme }) => theme.fontSizes.l};

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    font-size: ${({ theme }) => theme.fontSizes.xl};
  }
`;

const BlockText = styled.div`
  grid-area: text;
  overflow: hidden;
`;

const BubbleContainer = styled.div`
  position: relative;
  isolation: isolate;
`;

const Bubble = styled.div`
  --size: 1rem;

  position: absolute;
  z-index: -1;

  top: 50%;
  right: 50%;
  bottom: 50%;
  left: 50%;
  inset: 50%;

  width: var(--size);
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: var(--bubble-color);

  transform-origin: center;
  transform: var(--bubble-transform);
`;

// TODO Remove additional debugging div
const BubbleImage = ({ children }: { children: ReactNode }): ReactElement => (
  <BubbleContainer>
    <Bubble
      style={
        {
          "--bubble-transform": "rotate(50deg) scale(6) translateY(-200%)",
          "--bubble-color": "#2e008b",
        } as any
      }
    />
    <Bubble
      style={
        {
          "--bubble-transform": "rotate(220deg) scale(3) translateY(-400%)",
          "--bubble-color": "#e40087",
        } as any
      }
    />
    <Bubble
      style={
        {
          "--bubble-transform": "rotate(240deg) scale(1) translateY(-1100%)",
          "--bubble-color": "#c6c6c6",
        } as any
      }
    />

    {children}
  </BubbleContainer>
);

const ContentBlock = ({
  imageSrc,
  imageAlt = "",
  title,
  asH3 = false,
  children,
  rightAlignImage = false,
  titleFirst = false,
  vertical = false,
  block = false,
  large = false,
  hasIcon = false,
  imageSize = "normal",
  smallGap = false,
  bubble = false,
}: ContentBlockProps): ReactElement => (
  <Block
    rightAlignImage={rightAlignImage}
    titleFirst={titleFirst}
    vertical={vertical}
    block={block}
    smallGap={smallGap}
  >
    <BlockImage
      vertical={vertical}
      rightAlignImage={rightAlignImage}
      block={block}
    >
      {imageSrc && typeof imageSrc === "string" ? (
        bubble ? (
          <BubbleImage>
            <ContentImage
              src={imageSrc}
              alt={imageAlt}
              isIcon={hasIcon}
              size={imageSize}
            />
          </BubbleImage>
        ) : (
          <ContentImage
            src={imageSrc}
            alt={imageAlt}
            isIcon={hasIcon}
            size={imageSize}
          />
        )
      ) : typeof imageSrc === "number" ? (
        <ContentAmountIcon isIcon>
          <ContentAmount>£{imageSrc}</ContentAmount>
        </ContentAmountIcon>
      ) : (
        <SkeletonImage width={250} />
      )}
    </BlockImage>
    {asH3 ? (
      <BlockH3StyledHeading h3 margin="none">
        {title}
      </BlockH3StyledHeading>
    ) : (
      <BlockHeading h3={large} h4={!large} margin="none">
        {title}
      </BlockHeading>
    )}
    <BlockText>{children}</BlockText>
  </Block>
);

const SkeletonImage = styled.div<{ width: number }>`
  width: clamp(100px, 100%, ${({ width }) => width}px);
  aspect-ratio: 1 / 1;
  background-color: ${({ theme }) => theme.tokenColors.darkBlue_500};
  border-radius: 50%;
`;

const MissionBlock = (): ReactElement => (
  <ContentBlock
    title="Meet Oliver"
    imageSrc="/assets/donate-landing-page/oliver.png"
    imageAlt="Oliver walking through the water at an open-water swimming event"
    large
  >
    <>
      <Text>
        Oliver was diagnosed with tonsil cancer in 2009 after spotting swollen
        glands under his jaw. He then had a tonsillectomy followed by
        chemotherapy.
      </Text>
      <Text>
        Oliver also took part in a trial we funded, which investigated whether a
        new type of radiotherapy caused fewer side effects and if it worked as
        well as standard radiotherapy for head and neck cancers.
      </Text>
      <Text>
        &ldquo;I feel fortunate to have been offered the chance to help medical
        research. It&rsquo;s an exciting time &ndash; we need the investment and
        the funds now.&rdquo; &ndash; Oliver
      </Text>
    </>
  </ContentBlock>
);

type MultiColumnContainerProps = {
  children: Array<ReactElement>;
  columnIds: Array<string>;
};

const MultiColumnContainer = ({
  children,
  columnIds,
}: MultiColumnContainerProps): ReactElement => {
  if (children.length !== columnIds.length) {
    return null;
  }

  return (
    <MultiColumnDiv>
      {children.map((child, i) => (
        <div id={`${columnIds[i]}`} key={`${columnIds[i]}`}>
          {child}
        </div>
      ))}
    </MultiColumnDiv>
  );
};

const MultiColumnDiv = styled.div`
  display: grid;
  gap: 2.5rem;
  grid-auto-columns: 1fr;
  padding-top: 1.5rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.mobile}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const CtaButton = styled(Button)`
  display: block;
  margin-inline: auto;
`;

type SectionProps = {
  title?: string;
  children: ReactNode;
};

const SectionContent = styled.div<{ narrow?: boolean }>`
  max-width: ${({ theme }) => theme.breakpoint.tablet};
  margin-inline: auto;

  ${({ narrow }) =>
    narrow
      ? css`
          @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
            padding-inline: 6rem;
          }
        `
      : ""}
`;

const SectionHeading = styled(Heading)`
  font-size: ${({ theme }) => theme.fontSizes.xl};

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    font-size: ${({ theme }) => theme.fontSizes.xxl};
  }
`;

const Section = ({ title, children }: SectionProps): ReactElement => (
  <>
    {title && (
      <SectionHeading h3 textAlign="center" marginBottom="l">
        {title}
      </SectionHeading>
    )}
    {children}
  </>
);

const ImportanceBlock = (): ReactElement => {
  const router = useRouter();
  const { pushDonatePageInteraction } = useDataLayer();

  return (
    <Section>
      <SectionContent>
        <ContentBlock
          imageSrc="/assets/donate-landing-page/new-small-amounts.png"
          imageAlt="Alfred enjoying time with his partner"
          title="Small amounts add up"
          titleFirst
          rightAlignImage
          large
        >
          <Text>
            Every pound matters. But when you make a regular donation, even if
            it&rsquo;s a small amount, your impact builds up over time to help
            more people like Alfred.
          </Text>
        </ContentBlock>
        <ContentBlock
          imageSrc="/assets/donate-landing-page/new-you-donate.png"
          imageAlt="A researcher working with a member of her lab group"
          title="You donate. We discover."
          titleFirst
          large
        >
          <Text>
            A reliable income means we can plan ahead and commit to long-term
            research projects with huge potential to help beat cancer.
          </Text>
        </ContentBlock>
        <ContentBlock
          imageSrc="/assets/donate-landing-page/new-save-more.png"
          imageAlt="Mei-Ling spending time with her children at the park"
          title="Help save more lives"
          titleFirst
          rightAlignImage
          large
        >
          <Text>
            Your regular donations support over 4,000 researchers, clinicians
            and nurses to transform how we prevent, diagnose and treat cancer,
            bringing hope to millions of people like Mei-Ling, now and in the
            future.
          </Text>
        </ContentBlock>
      </SectionContent>
      <Box paddingVertical="l">
        <CtaButton
          onClick={() => {
            pushDonatePageInteraction("button click", "donate monthly cta");

            router
              .push("/support-us/your-donation?type=regular")
              .catch(console.error);
          }}
        >
          Donate monthly
        </CtaButton>
      </Box>
      <ContentBlock
        imageSrc="/assets/donate-landing-page/new-cog.png"
        title="Want to increase or make a change to your regular donation?"
        asH3
        hasIcon
        imageSize="small"
        smallGap
      >
        <>
          <Text>
            <Link
              onClick={() => {
                pushDonatePageInteraction(
                  "link click",
                  "increase regular gift"
                );
              }}
              href="https://www.cancerresearchuk.org/get-involved/donate/manage-your-donation"
            >
              Manage your regular donation here.
            </Link>
          </Text>
          <Text>
            If you&rsquo;d like to decrease or stop your regular donation,
            please call us on <PhoneNumber>0300 123 1861</PhoneNumber> or email{" "}
            <Email>supporter.services@cancer.org.uk</Email>. We are so grateful
            for whatever support you can offer to help beat cancer.
          </Text>
        </>
      </ContentBlock>
    </Section>
  );
};

const WhereYourDonationGoesBlock = (): ReactElement => (
  <Section title="How your money can support our life-saving work">
    <SectionContent narrow>
      <ContentBlock
        title="Support pioneering clinical trials"
        block
        imageSrc={10}
        smallGap
      >
        £10 a month for a year could fund a research nurse for one day to raise
        awareness of our trials and support and monitor patients during their
        treatment.
      </ContentBlock>
      <ContentBlock
        title="Find cancer's weaknesses"
        block
        imageSrc={25}
        smallGap
      >
        £25 a month for a year could buy a special machine used to spin blood
        samples until they separate. Our scientists can then study cancerous
        cells to find out more about them and target their weak spots.
      </ContentBlock>
      <ContentBlock
        title="Provide a lifeline to people affected by cancer"
        block
        imageSrc={40}
        smallGap
      >
        £40 a month could run Cancer Chat for a whole day &ndash; our safe
        online space open to everyone affected by cancer. Cancer is
        unpredictable, so let&rsquo;s give people affected by cancer the support
        they need at every turn.
      </ContentBlock>
      <ContentBlock
        title="Find the next big breakthrough"
        block
        imageSrc={50}
        smallGap
      >
        £50 a month for a year could fund a research assistant for a week,
        bringing us closer to the next cancer research breakthrough by helping
        to carry out important experiments.
      </ContentBlock>
    </SectionContent>
  </Section>
);

const RestrictedForm = styled.form`
  padding: unset;

  div:first-child > label {
    margin: unset;
    padding-block-end: 2.5rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.mobile}) {
    display: flex;
    gap: 1rem;

    div:first-child {
      width: 70%;
    }

    div:first-child > label {
      padding-block-end: unset;
    }

    div:last-child {
      align-self: end;
      flex-grow: 1;
      flex-basis: 11rem;
    }
  }
`;

// TODO Update researchAreas to include where the need is greatest
const ChooseWhereYourDonationGoesBlock = (): ReactElement => {
  const [restriction, setRestriction] = useState("");
  const [showError, setShowError] = useState(false);

  const router = useRouter();
  const { pushDonatePageInteraction } = useDataLayer();

  return (
    <Section title="Help us fund the research that matters most to you">
      <SectionContent>
        <Text>
          We&rsquo;re the only charity fighting over 200 types of cancer. So
          when you give to Cancer Research UK, we think carefully about where we
          spend the income across all cancer types.
        </Text>
        <Text>
          We have 17 areas of research you can choose to fund online. From bowel
          cancer to children&rsquo;s cancers or simply where the need is
          greatest.
        </Text>
        <Text>
          For every pound we receive, 81p is available to beat cancer.
        </Text>
        <RestrictedForm
          onSubmit={(e) => {
            e.preventDefault();

            pushDonatePageInteraction("button click", "donate now cta");

            const formEl = e.target as HTMLFormElement;
            const rawFormData = new FormData(formEl);
            const formData = Object.fromEntries(
              Array.from(rawFormData.entries()).filter(([, value]) => !!value)
            );

            const baseUrl = "/support-us/your-donation";
            const restrictionQuery =
              formData.restriction && formData.restriction !== "GREATEST"
                ? `?restriction=${formData.restriction.toString()}`
                : "";
            const url = `${baseUrl}${restrictionQuery}`;
            router.push(url).catch(console.error);
          }}
        >
          <div>
            <Select
              label="Select a research area and donate"
              value={restriction}
              onChange={(e) => {
                const res = e.target.value;
                if (res === "Unlisted") {
                  setShowError(true);
                  setRestriction("GREATEST");
                } else {
                  setShowError(false);
                  setRestriction(e.target.value);
                }
              }}
              name="restriction"
            >
              <GetSelectOptions
                options={[
                  { label: "Where the need is greatest", value: "GREATEST" },
                  ...researchAreas,
                ]}
              />
            </Select>
          </div>
          <Box marginHorizontal="auto">
            <CtaButton type="submit">Donate now</CtaButton>
          </Box>
        </RestrictedForm>
        {showError && (
          <ErrorBox role="region" aria-live="polite" marginTop="xs">
            <p>
              We&rsquo;re not able to list all areas of research here, if you
              can&rsquo;t find what you&rsquo;re looking for please call us on{" "}
              <PhoneNumber>0300 123 1022</PhoneNumber> to complete your
              donation.
            </p>
          </ErrorBox>
        )}
      </SectionContent>
    </Section>
  );
};

const ErrorBox = styled(Box)`
  border: 4px solid ${({ theme }) => theme.tokenColors.darkBlue_100};
  background-color: ${({ theme }) => theme.tokenColors.darkBlue_100};
  padding: 0 ${({ theme }) => theme.spacing.s};
  font-weight: bold;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const DonateHowYouWant = (): ReactElement => {
  const { pushDonatePageInteraction } = useDataLayer();

  return (
    <Section title="Donate in a way that suits you">
      <SectionContent>
        <Text>
          In the UK, someone is diagnosed with cancer every two minutes and your
          donation will make a real difference to those affected by cancer, now
          and in the future.
        </Text>
        <Text>
          Every pound is important. We committed £443m to research in 2021
          thanks to our supporters.
        </Text>
      </SectionContent>
      <MultiColumnContainer
        columnIds={[
          "donate-by-cheque",
          "pay-in-fundraising-money",
          "donate-to-family-or-friend",
        ]}
      >
        <ContentBlock
          imageSrc="/assets/donate-landing-page/new-mail.png"
          title="Donate by cheque and post"
          vertical
          hasIcon
        >
          <>
            <Text>
              Donating online is the quickest way to give. To donate by cheque
              or to set up a regular gift by post, download and print the
              relevant form below and send your donation to us.
            </Text>
            <BlockLink
              spaced
              appearance="primary"
              href="https://www.cancerresearchuk.org/sites/default/files/regular_donation_form.pdf"
              onClick={() => {
                pushDonatePageInteraction(
                  "file download",
                  "regular donation form"
                );
              }}
            >
              Regular donation form
            </BlockLink>
            <BlockLink
              appearance="primary"
              href="https://www.cancerresearchuk.org/sites/default/files/single_donation_form_0.pdf"
              onClick={() => {
                pushDonatePageInteraction(
                  "file download",
                  "single donation form"
                );
              }}
            >
              Single donation form
            </BlockLink>
          </>
        </ContentBlock>
        <ContentBlock
          imageSrc="/assets/donate-landing-page/new-collection.png"
          title="Pay in your fundraising money"
          vertical
          hasIcon
        >
          <>
            <Text>
              Congrats! If you&rsquo;ve finished fundraising, send us the money
              you&rsquo;ve raised so we can use it to help beat cancer now and
              in the future.
            </Text>
            <BlockLink
              appearance="primary"
              href="https://www.cancerresearchuk.org/get-involved/do-your-own-fundraising/pay-in-your-money-0"
              onClick={() => {
                pushDonatePageInteraction("link click", "send your money");
              }}
            >
              Send us your fundraising money
            </BlockLink>
          </>
        </ContentBlock>
        <ContentBlock
          imageSrc="/assets/donate-landing-page/new-people.png"
          title="Donate to a friend or family member"
          vertical
          hasIcon
        >
          <>
            <Text>
              To support your friends and family&rsquo;s fundraising, search for
              their giving page and donate to help change lives.
            </Text>
            <BlockLink
              appearance="primary"
              href="https://fundraise.cancerresearchuk.org/"
              onClick={() => {
                pushDonatePageInteraction("link click", "find a giving page");
              }}
            >
              Find a giving page
            </BlockLink>
          </>
        </ContentBlock>
      </MultiColumnContainer>
    </Section>
  );
};

const BlockLink = styled(Link)<{ spaced?: boolean }>`
  display: block;
  padding: unset;
  ${({ spaced }) =>
    spaced
      ? css`
          padding-block-end: 0.8rem;
        `
      : ""}
`;

const LinkList = styled.li`
  list-style: none;
  padding-block-end: 0.8rem;
`;

const UnorderedList = styled.ul`
  padding: unset;

  @media (min-width: ${({ theme }) => theme.breakpoint.mobile}) {
    padding-inline-start: 2.5rem;
  }
`;

const OtherWaysToDonate = (): ReactElement => (
  <Section title="Other ways to donate">
    <SectionContent>
      <Text>
        If you&rsquo;re not looking to give an online donation today there are
        other things you can do to support our amazing research.
      </Text>
      <UnorderedList>
        <LinkList>
          <Link
            appearance="primary"
            href="https://www.cancerresearchuk.org/get-involved/donate/donate-goods"
          >
            Donate goods to a shop
          </Link>
        </LinkList>
        <LinkList>
          <Link
            appearance="primary"
            href="https://www.cancerresearchuk.org/get-involved/donate/donate-in-memory"
          >
            Donate in memory
          </Link>
        </LinkList>
        <LinkList>
          <Link
            appearance="primary"
            href="https://www.cancerresearchuk.org/get-involved/leave-a-legacy-gift-in-your-will"
          >
            Leave a gift in your Will
          </Link>
        </LinkList>
        <LinkList>
          <Link
            appearance="primary"
            href="https://www.justgiving.com/inspiration/ideas/fundraise-your-way/celebrating-an-occasion"
          >
            Fundraise at celebrations and special occasions
          </Link>
        </LinkList>
        <LinkList>
          <Link
            appearance="primary"
            href="https://fundraise.cancerresearchuk.org/"
          >
            Fundraise through your own giving page
          </Link>
        </LinkList>
        <LinkList>
          <Link
            appearance="primary"
            href="https://www.cancerresearchuk.org/get-involved/donate/philanthropy"
          >
            Explore philanthropy with Cancer Research UK
          </Link>
        </LinkList>
        <LinkList>
          <Link
            appearance="primary"
            href="https://www.cancerresearchuk.org/get-involved/donate/donate-money/share-giving"
          >
            Give your shares to support our work
          </Link>
        </LinkList>
        <LinkList>
          <Link
            appearance="primary"
            href="https://www.cancerresearchuk.org/get-involved/donate/payroll-giving-for-employees"
          >
            Learn about payroll giving
          </Link>
        </LinkList>
        <LinkList>
          <Link
            appearance="primary"
            href="https://www.cancerresearchuk.org/get-involved/donate/gift-aid"
          >
            Find out about Gift Aid
          </Link>
        </LinkList>
      </UnorderedList>
    </SectionContent>
  </Section>
);

const SectionContainer = styled.div<{ fullBleed?: boolean }>`
  padding-inline: ${({ fullBleed, theme }) =>
    fullBleed ? "unset" : theme.spacing.s};
  padding-block-start: ${({ theme }) => theme.spacing.m};
  padding-block-end: ${({ theme }) => theme.spacing.xl};

  @media (min-width: ${({ theme }) => theme.breakpoint.mobile}) {
    padding-block-start: ${({ theme }) => theme.spacing.l};
    padding-block-end: ${({ theme }) => theme.spacing.xxl};
  }
`;

const DonatePageContent = (): ReactElement => (
  <>
    <Hero imageIndex={4} />
    <SectionContainer>
      <MaxWidth>
        <ImportanceBlock />
      </MaxWidth>
    </SectionContainer>

    <SectionContainer>
      <MaxWidth>
        <WhereYourDonationGoesBlock />
      </MaxWidth>
    </SectionContainer>

    <SectionContainer fullBleed>
      <FullBleedContent backgroundColor={newCrukTheme.tokenColors.darkBlue_100}>
        <MissionBlock />
      </FullBleedContent>
    </SectionContainer>

    <SectionContainer>
      <MaxWidth>
        <ChooseWhereYourDonationGoesBlock />
      </MaxWidth>
    </SectionContainer>

    <SectionContainer>
      <MaxWidth>
        <DonateHowYouWant />
      </MaxWidth>
    </SectionContainer>

    <SectionContainer>
      <MaxWidth>
        <OtherWaysToDonate />
      </MaxWidth>
    </SectionContainer>
  </>
);

const DonatePage = ({
  headerConfig,
  theme,
}: {
  headerConfig: any;
  theme: ThemeType;
}): ReactElement => {
  const [optimizelyContext] = useContext(OptimizelyContext);
  const { optimizely, optimizelyUserContext } = optimizelyContext;

  useOptimizelyTrack({
    event: "start_donate_page",
    client: optimizely,
    user: optimizelyUserContext,
  });

  return (
    <SiteContainer>
      <GlobalStyle theme={theme} formTheme="NEW_CRUK" />
      <FormHead
        page="Donate"
        title="Donate To Beat Cancer | Cancer Research UK"
        optimizelyId="2235241580"
      />
      <Header
        logoAltText={headerConfig.logoAlt}
        logoImageSrc={headerConfig.logoSrc}
        logoLinkTitle={headerConfig.logoTitle}
        logoLinkUrl={headerConfig.logoUrl}
        siteSlogan={headerConfig.siteSlogan ? headerConfig.siteSlogan : ""}
      >
        <Button
          appearance="secondary"
          href="https://find.cancerresearchuk.org/?size=n_20_n"
        >
          Search
        </Button>
      </Header>
      <NavBar />
      <main id="main">
        <h1>Donate to Cancer Research UK</h1>
        <DonatePageContent />
      </main>
      <Footer formTheme="CRUK" />
    </SiteContainer>
  );
};

const Page = (): ReactElement => {
  const headerConfig =
    newCrukTheme.name === "new_cruk" ? header.NEW_CRUK : header.CRUK;
  const [hasMounted, setHasMounted] = useState(false);
  const { pushOptimizelyExperiment } = useDataLayer();

  useEffect(() => {
    setCookieBanner();
    setCookieAccepted("CRUK", "donate");
    setDatadog();
    setHasMounted(true);

    const searchParams = new URLSearchParams(window.location.search);
    const bucketed = searchParams.get("oe") === "true";

    if (bucketed) {
      pushOptimizelyExperiment(
        "ts1__donate_landing_page_ab_test",
        true,
        "control_amplify_donate_page"
      );
    }
  }, []);

  if (!hasMounted) {
    return null;
  }

  return (
    <ThemeProvider theme={newCrukTheme}>
      <ThemeNameContext.Provider value="CRUK">
        <OptimizelyContextProvider>
          <OptimizelySetup />
          <DonatePage headerConfig={headerConfig} theme={newCrukTheme} />
        </OptimizelyContextProvider>
      </ThemeNameContext.Provider>
    </ThemeProvider>
  );
};

export default Page;
