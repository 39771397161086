import { crukTheme, ThemeType } from "@cruk/cruk-react-components";

const TOKEN_COLORS = {
  darkBlue_500: "#00007E",
  cyan_500: "#009CEE",

  magenta_500: "#E60079",
  magenta_800: "#CC006C",

  grey_900: "#000",
  black: "#000",
};

export const newCrukTheme: ThemeType = {
  ...crukTheme,
  name: "new_cruk",
  tokenColors: {
    ...crukTheme.tokenColors,
    magenta_800: TOKEN_COLORS.magenta_800,
    magenta_500: TOKEN_COLORS.magenta_500,
    darkBlue_500: TOKEN_COLORS.darkBlue_500,
    cyan_500: TOKEN_COLORS.cyan_500,
  },
  colors: {
    ...crukTheme.colors,
    primary: TOKEN_COLORS.black,
    secondary: TOKEN_COLORS.magenta_500,
    tertiary: TOKEN_COLORS.cyan_500,

    info: TOKEN_COLORS.cyan_500,

    linkColor: TOKEN_COLORS.darkBlue_500,
    linkColorHover: TOKEN_COLORS.magenta_800,
    textDark: TOKEN_COLORS.grey_900,

    progressBar: TOKEN_COLORS.darkBlue_500,
    progressBarSecondary: TOKEN_COLORS.magenta_500,
    circularProgress: TOKEN_COLORS.cyan_500,
    circularProgressSecondary: TOKEN_COLORS.magenta_500,

    buttonPrimaryBackground: TOKEN_COLORS.magenta_500,
    buttonPrimaryBorder: TOKEN_COLORS.magenta_500,
    buttonPrimaryBackgroundHover: TOKEN_COLORS.magenta_800,
    buttonPrimaryBorderHover: TOKEN_COLORS.magenta_800,

    buttonSecondaryText: TOKEN_COLORS.magenta_500,
    buttonSecondaryBorder: TOKEN_COLORS.magenta_500,
    buttonSecondaryTextHover: TOKEN_COLORS.magenta_800,
    buttonSecondaryBorderHover: TOKEN_COLORS.magenta_800,

    buttonTertiaryText: TOKEN_COLORS.magenta_500,
    buttonTertiaryTextHover: TOKEN_COLORS.magenta_800,

    loaderColor1: TOKEN_COLORS.darkBlue_500,
    loaderColor2: TOKEN_COLORS.magenta_500,
    loaderColor3: TOKEN_COLORS.cyan_500,

    paginationText: TOKEN_COLORS.darkBlue_500,
    paginationBackground: TOKEN_COLORS.darkBlue_500,

    totaliserBubbleTotalColor: TOKEN_COLORS.darkBlue_500,
  },
  button: {
    ...crukTheme.button,
    borderRadius: "0px",
    borderRadiusLarge: "0px",
  },
  typography: {
    ...crukTheme.typography,
    customFonts: [
      {
        family: "Progress-Regular",
        url: "/assets/fonts/Progress-Regular",
      },
    ],
    fontFamilyHeadings: "Progress-Regular,Calibri,Arial,sans-serif",
    fontFamilyBase: `poppinsregular, ${crukTheme.typography.fontFamilyBase}`,
  },
};

export default newCrukTheme;
